import React, { useEffect } from "react"
import gsap from "gsap"
import "./header-background.css";
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger);

const HeaderBackground = ({transitionStatus, img, children, className}: {transitionStatus: string, img: string, children: React.ReactNode, className}) => {

    // First Load Transition
    useEffect(() => {
        let section = document.querySelector(`.${className}`);
        let bg: HTMLDivElement = section.querySelector(`.header_bg`);

        gsap.to(bg, {
            opacity: 1,
            scale: 1,
            ease: "sine",
            delay: 0.4,
            duration: 0.8
        });

        gsap.to(section, {
            opacity: 1,
            duration: 0.8
        });

    }, []);

    // Enter-Exit Transitions
    useEffect(() => {
        let section = document.querySelector(`.${className}`);
        let bg: HTMLDivElement = section.querySelector(`.header_bg`);

        if (transitionStatus === 'entering') {
            gsap.to(bg, {
                opacity: 1,
                scale: 1,
                ease: "sine",
                delay: 0.4,
                duration: 0.8
            });
            gsap.to(section, {
                opacity: 1,
                duration: 0.8
            });
        }
        if (transitionStatus === 'exiting') {
            gsap.to(bg, {
                opacity: 0,
                duration: 0.3
            });
            gsap.to(section, {
                opacity: 0,
                duration: 0.3
            });
        }
    }, [transitionStatus]);

    return (
        <section className={`${className} header_bg_section overflow-hidden`} style={{ willChange: "transform", background: "rgba(222,217,214,1)" }}>
            <div className={"bg_container"}>
                <div className={'header_bg'} style={{
                    transformOrigin: "center",
                    willChange: "transform",
                    width: "100%",
                    backgroundColor: `rgb(239,237,224)`,
                    backgroundImage: `url(${img})`,
                    backgroundPosition: "center 25%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat"
                }}>
                    <div
                        className={"opacity-100 sm:opacity-0"}
                        style={{
                        position: "absolute",
                        top: 0,
                        transformOrigin: "center",
                        willChange: "transform",
                        width: "100%",
                        height: "100%",
                        background: `linear-gradient(2deg,rgba(242,242,236,1),rgba(242,242,236,0))`,
                    }}/>
                    {children}
                </div>
            </div>
        </section>
    )

}

export default HeaderBackground;
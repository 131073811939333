import React from "react";
import SEO from "../../components/seo/seo"
import HeaderBackground from "../../page-components/rewards-components/header-background"
import AnimateTexts from "../../components/animations/animate-texts"
import FormButton from "../../components/form-components/form-button"
import AnimateFade from "../../components/animations/animate-fade"
import InlineLink from "../../components/general/inline-link"
import CustomContainer from "../../components/general/contianer"
import FAQ from "../../page-components/faq/faq.json";
import { Disclosure } from "@headlessui/react";
import Footer from "../../components/footer/footer"
import { FiCheck } from "@react-icons/all-files/fi/FiCheck"
import { FiShoppingCart } from "@react-icons/all-files/fi/FiShoppingCart"
import { FiStar } from "@react-icons/all-files/fi/FiStar"
import { FiInstagram } from "@react-icons/all-files/fi/FiInstagram"
import { VscAccount } from "@react-icons/all-files/vsc/VscAccount"
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown"

const GroomdRewards = ({transitionStatus}) => {

    return (
        <>
            <SEO description={"Become a member today (FREE!) to enter a world of benefits. Earn points, get exclusive offers, special shopping event invites and more!"} title={"Groomd Men's Club Rewards"} pathname={"/groomd-rewards"}/>
            <HeaderBackground transitionStatus={transitionStatus} img={"https://ik.imagekit.io/groomd/web_assets/circle_man.jpg?tr=f-webp"} className={"groomd-rewards-image"}>
                <div className={"flex flex-col justify-center items-center py-32 pb-48 sm:py-40 sm:pb-96 2xl:py-48 2xl:pb-96"}>
                    <AnimateTexts className={`rewards_heading`} transitionStatus={transitionStatus} delay={500}>
                        <div className={"max-w-2xl 2xl:max-w-4xl"}>
                            <p className={"text-gray-800 font-semibold font-sans uppercase tracking-widest text-md text-center mb-2"} data-text-animate={true}>
                                MEMBERS GET MORE
                            </p>
                            <h1 className={"text-gray-800 font-bold font-serif text-3xl md:text-5xl 2xl:text-6xl text-center px-4"} data-text-animate={true}>
                                Earn points on every purchase, enjoy exclusive perks, discounts & more!
                            </h1>
                        </div>
                    </AnimateTexts>
                    <AnimateFade className={"rewards_heading_button"} rootClass={"mt-12"} transitionStatus={transitionStatus} delay={550}>
                        <div data-animate-fade={true}>
                            <FormButton to={"/account/become-a-member"} disableArrowAnimation>
                                Become a Member
                            </FormButton>
                        </div>
                    </AnimateFade>
                    <AnimateFade className={"rewards_heading_subtext"} rootClass={"mt-3"} transitionStatus={transitionStatus} delay={600}>
                        <div data-animate-fade={true}>
                            <p className={"text-gray-800 font-medium opacity-70 font-sans text-sm text-center"}>
                                Already have an account?
                                <InlineLink to={"/account/sign-in"} style={{marginLeft: "4px"}}>
                                    <span className={"font-semibold opacity-100"}>
                                          Sign In
                                    </span>
                                </InlineLink>
                            </p>
                        </div>
                    </AnimateFade>
                </div>
            </HeaderBackground>
            <section className={"relative py-12 sm:py-24 overflow-hidden"}>
                <CustomContainer>
                    <AnimateTexts className={`rewards_how_it_works`} transitionStatus={transitionStatus} delay={100}>
                        <div className={"grid grid-cols-1 sm:grid-cols-3"}>
                            <div className={"px-6 py-3 sm:px-0 sm:py-6 border-l-4 border-b-0 sm:border-l-0 sm:border-b-4 border-gray-600"}>
                                <p className={"text-gray-800 font-semibold font-sans text-5xl 2xl:text-6xl mb-2"} data-text-animate={true}>
                                    01
                                </p>
                                <p className={"text-gray-800 font-semibold font-sans text-2xl mb-2"} data-text-animate={true}>
                                    Become a Member
                                </p>
                                <p className={"text-gray-600 font-regular font-sans text-lg mb-2 w-1/2"} data-text-animate={true}>
                                    Join the rewards programs
                                    and start earning.
                                </p>
                            </div>
                            <div className={"px-6 py-3 sm:px-0 sm:py-6 border-l-4 border-b-0 sm:border-l-0 sm:border-b-4 border-gray-200"}>
                                <p className={"text-gray-800 font-semibold font-sans text-5xl 2xl:text-6xl mb-2"} data-text-animate={true}>
                                    02
                                </p>
                                <p className={"text-gray-800 font-semibold font-sans text-2xl mb-2"} data-text-animate={true}>
                                    Earn points
                                </p>
                                <p className={"text-gray-600 font-regular font-sans text-lg mb-2 w-1/2"} data-text-animate={true}>
                                    Earn points every time
                                    you shop.
                                </p>
                            </div>
                            <div className={"px-6 py-3 sm:px-0 sm:py-6 border-l-4 border-b-0 sm:border-l-0 sm:border-b-4 border-gray-200"}>
                                <p className={"text-gray-800 font-semibold font-sans text-5xl 2xl:text-6xl mb-2"} data-text-animate={true}>
                                    03
                                </p>
                                <p className={"text-gray-800 font-semibold font-sans text-2xl mb-2"} data-text-animate={true}>
                                    Redeem
                                </p>
                                <p className={"text-gray-600 font-regular font-sans text-lg mb-2 w-1/2"} data-text-animate={true}>
                                    For every 500 points you will receive a ₹15 Reward
                                </p>
                            </div>
                        </div>
                    </AnimateTexts>
                </CustomContainer>
            </section>
            <section className={"relative py-12 sm:py-24 overflow-hidden"} style={{ background: "hsl(46,28%,91%)" }}>
                <CustomContainer>
                    <AnimateTexts className={`rewards_benefits_text`} transitionStatus={transitionStatus} delay={100}>
                        <h3 className={"text-gray-800 font-semibold font-sans text-2xl"} data-text-animate={true}>
                            The Benefits
                        </h3>
                        <p className={"text-gray-600 font-regular font-sans text-lg mb-2"} data-text-animate={true}>
                            The more you spend, the more exclusive perks you unlock!
                        </p>
                    </AnimateTexts>
                    <AnimateFade className={`rewards_benefits_content`} transitionStatus={transitionStatus} delay={100}>
                        <div className={"grid grid-cols-6"} data-animate-fade="true">
                            <div className={"py-2 flex items-end col-span-3 border-b border-gray-600"}>
                                <p className={"text-gray-900 font-medium font-sans text-lg"}>
                                    Benefits
                                </p>
                            </div>
                            <div className={"py-2 border-b border-gray-600"}>
                                <p className={"text-gray-900 font-regular font-sans text-xs sm:text-lg"}>
                                    Member
                                </p>
                                <p className={"text-gray-500 font-regular font-sans text-xs sm:text-sm"}>
                                    0 pts.
                                </p>
                            </div>
                            <div className={"py-2 border-b border-gray-600"}>
                                <p className={"font-regular font-sans text-xs sm:text-lg"} style={{ color: "rgb(168, 116, 70)" }}>
                                    Gold
                                </p>
                                <p className={"text-gray-500 font-regular font-sans text-xs sm:text-sm"}>
                                    1K+ pts.
                                </p>
                            </div>
                            <div className={"py-2 border-b border-gray-600"}>
                                <p className={"font-regular font-sans text-xs sm:text-lg"} style={{ color: "rgb(132,132,145)" }}>
                                    Platinum
                                </p>
                                <p className={"text-gray-500 font-regular font-sans text-xs sm:text-sm"}>
                                    10K+ pts.
                                </p>
                            </div>


                            <div className={"py-4 flex items-end col-span-3 border-b border-gray-400"}>
                                <p className={"text-gray-900 font-regular font-sans text-base"}>
                                    Point Multiplier
                                </p>
                            </div>
                            <div className={"py-4 border-b border-gray-400"}>
                                <p className={"text-gray-800 font-regular font-sans text-base"}>
                                    1x
                                </p>
                            </div>
                            <div className={"py-4 border-b border-gray-400"}>
                                <p className={"text-gray-800 font-regular font-sans text-base"} style={{ color: "rgb(168, 116, 70)" }}>
                                    1x
                                </p>
                            </div>
                            <div className={"py-4 border-b border-gray-400"}>
                                <p className={"text-gray-800 font-regular font-sans text-base"} style={{ color: "rgb(132,132,145)" }}>
                                    1.5x
                                </p>
                            </div>


                            <div className={"py-4 flex items-end col-span-3 border-b border-gray-400"}>
                                <p className={"text-gray-900 font-regular font-sans text-base"}>
                                    Level-Up Gift
                                </p>
                            </div>
                            <div className={"py-4 border-b border-gray-400"}>
                                <p className={"text-gray-800 font-regular font-sans text-base"}>
                                    200
                                </p>
                            </div>
                            <div className={"py-4 border-b border-gray-400"}>
                                <p className={"text-gray-800 font-regular font-sans text-base"} style={{ color: "rgb(168, 116, 70)" }}>
                                    250
                                </p>
                            </div>
                            <div className={"py-4 border-b border-gray-400"}>
                                <p className={"text-gray-800 font-regular font-sans text-base"} style={{ color: "rgb(132,132,145)" }}>
                                    500
                                </p>
                            </div>


                            <div className={"py-4 flex items-end col-span-3 border-b border-gray-400"}>
                                <p className={"text-gray-900 font-regular font-sans text-base"}>
                                    Exclusive Discounts
                                </p>
                            </div>
                            <div className={"py-4 border-b border-gray-400"}>
                                <p className={"text-gray-800 font-regular font-sans text-base"}>

                                </p>
                            </div>
                            <div className={"py-4 border-b border-gray-400"}>
                                <p className={"text-gray-800 font-regular font-sans text-base"} style={{ color: "rgb(168, 116, 70)" }}>
                                    <FiCheck size={24} />
                                </p>
                            </div>
                            <div className={"py-4 border-b border-gray-400"}>
                                <p className={"text-gray-800 font-regular font-sans text-base"} style={{ color: "rgb(132,132,145)" }}>
                                    <FiCheck size={24} />
                                </p>
                            </div>


                            <div className={"py-4 flex items-end col-span-3 border-b border-gray-400"}>
                                <p className={"text-gray-900 font-regular font-sans text-base"}>
                                    Birthday Discount
                                </p>
                            </div>
                            <div className={"py-4 border-b border-gray-400"}>

                            </div>
                            <div className={"py-4 border-b border-gray-400"}>
                                <p className={"text-gray-800 font-regular font-sans text-base"} style={{ color: "rgb(168, 116, 70)" }}>
                                    10%
                                </p>
                            </div>
                            <div className={"py-4 border-b border-gray-400"}>
                                <p className={"text-gray-800 font-regular font-sans text-base"} style={{ color: "rgb(132,132,145)" }}>
                                    20%
                                </p>
                            </div>
                            <div className={"pt-4 col-span-6"}>
                                <p className={"text-gray-900 font-medium font-sans text-xl mb-2"}>
                                    For every 500 points you will receive a ₹15 Reward
                                </p>
                            </div>
                        </div>
                    </AnimateFade>
                </CustomContainer>
            </section>
            <section className={"relative py-12 sm:py-24 overflow-hidden"}>
                <CustomContainer>
                    <AnimateTexts className={`rewards_earn_points_text`} transitionStatus={transitionStatus} delay={100}>
                        <h3 className={"text-gray-800 font-semibold font-sans text-2xl"} data-text-animate={true}>
                            Ways To Earn Points
                        </h3>
                        <p className={"text-gray-600 font-regular font-sans text-lg mb-2"} data-text-animate={true}>
                            Your points will automatically register when you're signed in to your member account.
                        </p>
                    </AnimateTexts>
                    <AnimateFade className={`rewards_earn_points_content`} transitionStatus={transitionStatus} delay={100}>
                        <div className={"grid grid-cols-2 sm:grid-cols-4 mt-8 -space-x-px -space-y-px"} data-animate-fade="true">
                            <div className={"border py-4 flex flex-col justify-start items-center space-y-4"}>
                                <VscAccount size={36}/>
                                <p className={"text-gray-900 font-medium font-sans text-base text-center"}>
                                    200 Points <br/>
                                    <span className={"opacity-75"}>
                                        Creating Account
                                    </span>
                                </p>
                            </div>
                            <div className={"border py-4 flex flex-col justify-start items-center space-y-4"}>
                                <FiShoppingCart size={36} strokeWidth={1.5}/>
                                <p className={"text-gray-900 font-medium font-sans text-base text-center"}>
                                    1 Point<br/>
                                    <span className={"opacity-75"}>
                                        Per ₹1 Spent*
                                    </span>
                                </p>
                            </div>
                            <div className={"border py-4 flex flex-col justify-start items-center space-y-4"}>
                                <FiStar size={36} strokeWidth={1.5}/>
                                <p className={"text-gray-900 font-medium font-sans text-base text-center"}>
                                    100 Points<br/>
                                    <span className={"opacity-75"}>
                                        Review a Product
                                    </span>
                                </p>
                            </div>
                            <div className={"border py-4 flex flex-col justify-start items-center space-y-4"}>
                                <FiInstagram size={36} strokeWidth={1.5}/>
                                <p className={"text-gray-900 font-medium font-sans text-base text-center"}>
                                    100 Points<br/>
                                    <span className={"opacity-75"}>
                                        Follow on Instagram
                                    </span>
                                </p>
                            </div>
                        </div>
                    </AnimateFade>
                </CustomContainer>
            </section>
            <section className={"relative py-12 overflow-hidden"}>
                <CustomContainer>
                    <AnimateTexts className={`rewards_faq_text`} transitionStatus={transitionStatus} delay={100}>
                        <h3 className={"text-gray-800 font-semibold font-sans text-2xl text-center"} data-text-animate={true}>
                            FAQ
                        </h3>
                    </AnimateTexts>
                    <AnimateFade className={`rewards_faq_content`} transitionStatus={transitionStatus} delay={100}>
                        <div className={"grid grid-cols-1 gap-4 max-w-prose mx-auto mt-8"}>
                            {FAQ.filter((q) => { return q.category === "club" }).map((q, i) => {
                                return (
                                    <Disclosure key={`rewards_faq_${i}`}>
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className={`flex justify-between w-full px-4 py-3 text-base font-sans font-regular text-left text-gray-900 bg-background-dark rounded-lg ${ open ? "shadow-inner" : "" } transition duration-200 hover:shadow-inner  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}>
                                                    <span >{q.question}</span>
                                                    <FiChevronDown
                                                        className={`${
                                                            open ? 'transform rotate-180' : ''
                                                        } w-5 h-5 text-gray-500`}
                                                    />
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="font-sans font-regular text-base px-4 pt-0 border-b pb-4 text-sm text-gray-800">
                                                    {q.answer}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                )
                            })

                            }
                        </div>
                    </AnimateFade>
                </CustomContainer>
            </section>
            <Footer transitionStatus={transitionStatus}/>
        </>
    )

}

export default GroomdRewards;